$(function () {
    $('.main-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.slide',
        dots: false,
        fade: true,
        arrows: false,
    });

    $('.main-slider .next').click(function () {
       $('.main-slider').slick('slickNext');
    });

    $('.main-slider .prev').click(function () {
       $('.main-slider').slick('slickPrev');
    });

    $('.main-slider .slide-numbers a').click(function () {
        $('.main-slider .slide-numbers a').removeClass('active');
        $(this).addClass('active');
        var dotIndex = $(this).data('index');
        $('.main-slider').slick('slickGoTo', dotIndex);

    })
    $(".main-slider").on("afterChange", function (event, slick, currentSlide, nextSlide){
        $('.main-slider .slide-numbers a').removeClass('active');
        $(".main-slider .slide-numbers").find("[data-index='" + currentSlide + "']").addClass('active');

    });




    $('.products-slider').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        slide: '.product-slide-box',
        dots: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.products-slider-holder .next').click(function () {
        $('.products-slider').slick('slickNext');
    });

    $('.products-slider-holder .prev').click(function () {
        $('.products-slider').slick('slickPrev');
    });


    $('.video-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.slide',
        dots: false,
        arrows: false,
    });
    $('.video-slider .next').click(function () {
        $('.video-slider').slick('slickNext');
    });

    $('.video-slider .prev').click(function () {
        $('.video-slider').slick('slickPrev');
    });
    $(".video-slider").on("afterChange", function (event, slick, currentSlide, nextSlide){
        $('.yvideo').each(function(){
            var el_src = $(this).attr("src");
            $(this).attr("src",el_src);
        });
    });

    $('.fair-slider').slick({
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        slide: '.fair-box',
        dots: false,
        arrows: false,
    });
    $('.fair-slider-holder .next').click(function () {
        $('.fair-slider').slick('slickNext');
    });

    $('.fair-slider-holder .prev').click(function () {
        $('.fair-slider').slick('slickPrev');
    });


    $('.product-detail-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.slide',
        dots: false,
        arrows: false,
    });
    $('.product-detail-slider .next').click(function () {
        $('.product-detail-slider').slick('slickNext');
    });

    $('.product-detail-slider .prev').click(function () {
        $('.product-detail-slider').slick('slickPrev');
    });
});

$('.mobile-menu-button').click(function (e) {
    $('header').toggleClass('menu-active');
});